import React from "react";
import LayoutComponent from "../components/layout.component";


class NotFoundPage extends React.Component {
  render() {
    // const { data } = this.props

    return (
      <LayoutComponent location={this.props.location}>
        <h1>Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </LayoutComponent>
    )
  }
}

export default NotFoundPage

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `